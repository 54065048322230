import React, { useRef } from 'react';
import { Scatter } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
} from 'chart.js';
import html2canvas from 'html2canvas';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Filler, CategoryScale);

const SWOTScatterPlot = ({ sValue, wValue, oValue, tValue }) => {
  const chartRef = useRef(null);

  // Define individual points using the values passed from the tables
  const s = [sValue, 0.00];  // S: Silné stránky (Strengths)
  const w = [wValue, 0.00];  // W: Slabé stránky (Weaknesses)
  const o = [0.00, oValue];  // O: Príležitosti (Opportunities)
  const t = [0.00, tValue];  // T: Hrozby (Threats)

  // Points array
  const points = [o, s, t, w];  // Ordered as per connections

  const connections = [
    { start: 0, end: 1, color: '#000000' },  // Príležitosti -> Silné stránky
    { start: 1, end: 2, color: '#000000' },  // Silné stránky -> Hrozby
    { start: 2, end: 3, color: '#000000' },  // Hrozby -> Slabé stránky
    { start: 3, end: 0, color: '#000000' },  // Slabé stránky -> Príležitosti
  ];

  const datasets = connections.map(({ start, end, color }) => ({
    label: `Connection ${start} to ${end}`,
    data: [
      { x: points[start][0], y: points[start][1] },
      { x: points[end][0], y: points[end][1] },
      { x: 0, y: 0 },  // Center point for the fill
    ],
    borderColor: color,
    backgroundColor: color,
    fill: false,
    showLine: true,
    pointRadius: 0, // Hide the points for the lines
  }));

  datasets.push({
    label: 'Data Points',
    data: points.map((point) => ({ x: point[0], y: point[1], label: `(${point[0].toFixed(2)}, ${point[1].toFixed(2)})` })),
    pointBackgroundColor: 'blue',
    pointBorderColor: 'blue',
    pointRadius: 5,
    showLine: false,
  });

  // Calculate the midpoint (Ariatte)
  const x_mid = points.reduce((sum, p) => sum + p[0], 0) / points.length;
  const y_mid = points.reduce((sum, p) => sum + p[1], 0) / points.length;
  datasets.push({
    label: 'Mid Point',
    data: [{ x: x_mid, y: y_mid, label: 'Result' }],
    pointBackgroundColor: 'green',
    pointBorderColor: 'green',
    pointRadius: 10,
    showLine: false,
  });

  // Determine the maximum absolute value to set the symmetrical axis limits
  const maxAbsValue = Math.max(
    ...points.flat().map(Math.abs),
    1  // Ensure the limit is at least 1 to keep the original scaling if all values are small
  );

  const chartData = {
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: -maxAbsValue,  // Symmetrical limits based on the maximum absolute value
        max: maxAbsValue,
        grid: {
          display: true,         // Show grid lines
          color: 'lightgray',    // Set color for grid lines to differentiate from axes
          lineWidth: 1,
          drawBorder: true,      // Ensure the axis line (border) is drawn
          drawOnChartArea: true, // Draw grid lines over the chart area
          drawTicks: true,       // Show ticks on the grid
          z: 1,                  // Ensure grid lines are drawn below the points
        },
        ticks: {
          display: true,
          color: 'black',        // Color for x-axis ticks
        },
        axis: 'x',                // Explicitly define this as x-axis
        offset: false,
      },
      y: {
        type: 'linear',
        position: 'left',
        min: -maxAbsValue,  // Symmetrical limits based on the maximum absolute value
        max: maxAbsValue,
        grid: {
          display: true,         // Show grid lines
          color: 'lightgray',    // Set color for grid lines to differentiate from axes
          lineWidth: 1,
          drawBorder: true,      // Ensure the axis line (border) is drawn
          drawOnChartArea: true, // Draw grid lines over the chart area
          drawTicks: true,       // Show ticks on the grid
          z: 1,                  // Ensure grid lines are drawn below the points
        },
        ticks: {
          display: true,
          color: 'black',        // Color for y-axis ticks
        },
        axis: 'y',               // Explicitly define this as y-axis
        offset: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return context.raw.label || `(${context.raw.x.toFixed(2)}, ${context.raw.y.toFixed(2)})`;
          },
        },
      },
    },
  };

  const downloadChartAsImage = () => {
    const chartElement = chartRef.current;
    html2canvas(chartElement).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'SWOT_Analysis_Quadrant.png';
      link.click();
    });
  };

  return (
    <div>
      <div ref={chartRef} style={{ position: 'relative', width: '600px', height: '600px' }}>
        <Scatter data={chartData} options={options} />
        <div style={{ position: 'absolute', top: '50%', left: '90%', transform: 'translate(-50%, -50%)', fontSize: '12px' }}>
          Strengths
        </div>
        <div style={{ position: 'absolute', top: '50%', left: '10%', transform: 'translate(-50%, -50%)', fontSize: '12px' }}>
          Weaknesses
        </div>
        <div style={{ position: 'absolute', top: '10%', left: '50%', transform: 'translate(-50%, -50%) rotate(-90deg)', fontSize: '12px' }}>
          Opportunities
        </div>
        <div style={{ position: 'absolute', top: '90%', left: '50%', transform: 'translate(-50%, -50%) rotate(-90deg)', fontSize: '12px' }}>
          Threats
        </div>
        <div style={{ position: 'absolute', top: '25%', left: '75%', transform: 'translate(-50%, -50%)', fontSize: '14px', fontWeight: 'bold' }}>
          Offensive Strategy
        </div>
        <div style={{ position: 'absolute', top: '25%', left: '25%', transform: 'translate(-50%, -50%)', fontSize: '14px', fontWeight: 'bold' }}>
          Alliance Strategy
        </div>
        <div style={{ position: 'absolute', top: '75%', left: '25%', transform: 'translate(-50%, -50%)', fontSize: '14px', fontWeight: 'bold' }}>
          Diversification Strategy
        </div>
        <div style={{ position: 'absolute', top: '75%', left: '75%', transform: 'translate(-50%, -50%)', fontSize: '14px', fontWeight: 'bold' }}>
          Defensive Strategy
        </div>
      </div>
      <div className="export-btn-container">
        <button onClick={downloadChartAsImage} className="export-png-btn">
          Download as PNG
        </button>
      </div>
    </div>
  );
};

export default SWOTScatterPlot;
