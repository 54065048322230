import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import './ToggleSwitch.css';

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
]);

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchPosition: 'left', // Default position
      animation: null,
    };
  }

  getSwitchAnimation = (value) => {
    const { switchPosition } = this.state;
    let animation = null;

    if (value === 'right' && switchPosition === 'left') {
      animation = 'left-to-right';
    } else if (value === 'left' && switchPosition === 'right') {
      animation = 'right-to-left';
    }

    this.props.onChange(value);
    this.setState({ switchPosition: value, animation });
  };

  render() {
    const { labels } = this.props;

    return (
      <div className="main-container">
        <div
          className={`switch ${this.state.animation} ${this.state.switchPosition}-position`}
        ></div>
        <input
          defaultChecked
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="left"
          type="radio"
          value="left"
        />
        <label
          className={`left-label ${
            this.state.switchPosition === 'left' && 'black-font'
          }`}
          htmlFor="left"
          data-tooltip-id="tooltip-left"
        >
          <h4>{labels.left.title}</h4>
        </label>
        <Tooltip id="tooltip-left" content={labels.left.tooltip} />

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="right"
          type="radio"
          value="right"
        />
        <label
          className={`right-label ${
            this.state.switchPosition === 'right' && 'black-font'
          }`}
          htmlFor="right"
          data-tooltip-id="tooltip-right"
        >
          <h4>{labels.right.title}</h4>
        </label>
        <Tooltip id="tooltip-right" content={labels.right.tooltip} />
      </div>
    );
  }
}

// Corrected PropTypes
ToggleSwitch.propTypes = {
  labels: PropTypes.shape({
    left: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: valueType,
      tooltip: PropTypes.string,
    }).isRequired,
    right: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: valueType,
      tooltip: PropTypes.string,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleSwitch;
