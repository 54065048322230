import React from 'react';
import './Footer.css'; // Assuming you'll add some styles for the footer

const Footer = () => {
  return (
    <footer className="app-footer">
        <p className="handwritten">&copy; 2024 created by <a
            href="https://www.linkedin.com/in/phladis"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-link"
        >
            PeterH
        </a> . All rights reserved.</p>

    </footer>
  );
};

export default Footer;
