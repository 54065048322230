import React from 'react';
import './Table.css'; // Custom CSS for responsive styles

const Table = ({ data = [], onAddRow, onUpdateRow, onRemoveRow }) => {
  const handleNameChange = (index, newName) => {
    const updatedRow = { ...data[index], name: newName };
    onUpdateRow(index, updatedRow);
  };

  const handlePointsChange = (index, newPoints) => {
    const updatedRow = { ...data[index], points: newPoints };
    updatedRow.score = newPoints * updatedRow.weight; // Automatically calculate the score
    onUpdateRow(index, updatedRow);
  };

  const handleWeightChange = (index, newWeight) => {
    const updatedRow = { ...data[index], weight: newWeight };
    updatedRow.score = newWeight * updatedRow.points; // Automatically calculate the score
    onUpdateRow(index, updatedRow);
  };

  // Calculate the sums for Points, Weight, and Score
  const totalPoints = data.reduce((sum, row) => sum + Number(row.points || 0), 0);
  const totalWeight = data.reduce((sum, row) => sum + Number(row.weight || 0), 0);
  const totalScore = data.reduce((sum, row) => sum + Number(row.score || 0), 0);

  // Calculate the averages for Points, Weight, and Score
  const numberOfRows = data.length;
  const averagePoints = numberOfRows > 0 ? (totalPoints / numberOfRows).toFixed(2) : 0;
  const averageWeight = numberOfRows > 0 ? (totalWeight / numberOfRows).toFixed(2) : 0;
  const averageScore = numberOfRows > 0 ? (totalScore / numberOfRows).toFixed(2) : 0;

  return (
    <div className="table-container">
      <table className="responsive-table">
        <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Points</th>
          <th>Weight</th>
          <th>Score</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
            <tr key={index}>
              <td data-label="ID">{index + 1}</td>
              <td data-label="Name">
                <input
                    type="text"
                    value={row.name || ''}
                    onChange={(e) => handleNameChange(index, e.target.value)}
                />
              </td>
              <td data-label="Points">
                <input
                    type="number"
                    value={row.points || ''}
                    onChange={(e) => handlePointsChange(index, e.target.value)}
                    style={{width: '60px', textAlign: 'center'}}
                />
              </td>
              <td data-label="Weight">
                <input
                    type="number"
                    value={row.weight || ''}
                    onChange={(e) => handleWeightChange(index, e.target.value)}
                    style={{width: '60px', textAlign: 'center'}}
                />
              </td>
              <td data-label="Score">
                <span>{row.score || 0}</span>
              </td>
              <td data-label="Actions">
                <button onClick={() => onRemoveRow(index)}>Remove</button>
              </td>
            </tr>
        ))}
        {/* Total row */}
        <tr className="total-row">
          <td colSpan="2" style={{textAlign: 'right', fontWeight: 'bold'}}>Total</td>
          <td data-label="Total Points" style={{textAlign: 'center', fontWeight: 'bold'}}>{totalPoints.toFixed(2)}</td>
          <td data-label="Total Weight" style={{textAlign: 'center', fontWeight: 'bold'}}>{totalWeight.toFixed(2)}</td>
          <td data-label="Total Score" style={{textAlign: 'center', fontWeight: 'bold'}}>{totalScore.toFixed(2)}</td>
          <td></td>
        </tr>
        {/* Average row */}
        <tr className="average-row">
          <td colSpan="2" style={{textAlign: 'right', fontWeight: 'bold'}}>Average</td>
          <td data-label="Average Points" style={{textAlign: 'center', fontWeight: 'bold'}}>{averagePoints}</td>
          <td data-label="Average Weight" style={{textAlign: 'center', fontWeight: 'bold'}}>{averageWeight}</td>
          <td data-label="Average Score" style={{textAlign: 'center', fontWeight: 'bold'}}>{averageScore}</td>
          <td></td>
        </tr>
        </tbody>
      </table>
      <button onClick={onAddRow}>Add Row</button>
    </div>
  );
};

export default Table;
