import React, { useState } from 'react';
import Table from './components/Table';
import SwotChart from "./components/SwotChart";
import './App.css';  // Existing styles
import './AppResponsive.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import StrategyText from "./components/StrategyText";
import ToggleSwitch from './components/ToggleSwitch';
import CookieBanner from "./components/CookieBanner";
import MicrosoftClarity from './components/MicrosoftClarity'; // Import MicrosoftClarity component
import { saveAs } from 'file-saver';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const App = () => {
  const [strengths, setStrengths] = useState([
    { id: 1, name: 'Strength 1', points: 10, weight: 0.1, score: 1 },
  ]);
  const [weaknesses, setWeaknesses] = useState([
    { id: 1, name: 'Weakness 1', points: 5, weight: 0.1, score: 0.5 },
  ]);
  const [opportunities, setOpportunities] = useState([
    { id: 1, name: 'Opportunity 1', points: 7, weight: 0.1, score: 0.7 },
  ]);
  const [threats, setThreats] = useState([
    { id: 1, name: 'Threat 1', points: 4, weight: 0.1, score: 0.4 },
  ]);

  const [valueType, setValueType] = useState('total'); // State to manage the switch between total and average
  const [clarityEnabled, setClarityEnabled] = useState(false);
  const handleExportCSV = () => {
    const dataToCSV = (label, data) => {
      return [
        `${label}`,
        'ID,Name,Points,Weight,Score',
        ...data.map(row => `${row.id},${row.name},${row.points},${row.weight},${row.score}`)
      ].join('\n');
    };

    const csvContent = [
      dataToCSV('Strengths', strengths),
      dataToCSV('Weaknesses', weaknesses),
      dataToCSV('Opportunities', opportunities),
      dataToCSV('Threats', threats)
    ].join('\n\n'); // Separate each table with a blank line

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'SWOT_Analysis_(swotapp.online).csv');
  };

  const handleAddRow = (dataSetter, data, tableName) => {
    const newRow = {
      id: data.length + 1,
      name: `${tableName} ${data.length + 1}`, // Automatically set the name
      points: 1, // Default Points
      weight: 0.1, // Default Weight
      score: 1 * 0.1, // Default Score (points * weight)
    };
    dataSetter([...data, newRow]);
  };

  const handleUpdateRow = (index, updatedRow, data, dataSetter) => {
    updatedRow.score = updatedRow.points * updatedRow.weight; // Recalculate the score
    const newData = [...data];
    newData[index] = updatedRow;
    dataSetter(newData);
  };

  const handleRemoveRow = (index, data, dataSetter) => {
    const newData = data.filter((_, i) => i !== index);
    newData.forEach((row, i) => {
      row.id = i + 1; // Reassign IDs after removal
    });
    dataSetter(newData);
  };

  const sumStrengths = strengths.reduce((sum, row) => sum + Number(row.score), 0);
  const sumWeaknesses = weaknesses.reduce((sum, row) => sum + Number(row.score), 0);
  const sumOpportunities = opportunities.reduce((sum, row) => sum + Number(row.score), 0);
  const sumThreats = threats.reduce((sum, row) => sum + Number(row.score), 0);

  const avgStrengths = sumStrengths / (strengths.length || 1);
  const avgWeaknesses = sumWeaknesses / (weaknesses.length || 1);
  const avgOpportunities = sumOpportunities / (opportunities.length || 1);
  const avgThreats = sumThreats / (threats.length || 1);

  const handleSwitchChange = (newValueType) => {
    setValueType(newValueType === 'left' ? 'total' : 'average');
  };

  const handleConsent = (isAccepted) => {
    console.log('Cookie consent given:', isAccepted);
    setClarityEnabled(isAccepted);
  };

  const sValue = valueType === 'total' ? sumStrengths : avgStrengths;
  const wValue = valueType === 'total' ? sumWeaknesses : avgWeaknesses;
  const oValue = valueType === 'total' ? sumOpportunities : avgOpportunities;
  const tValue = valueType === 'total' ? sumThreats : avgThreats;

  return (
    <HelmetProvider>
      <div className="app-container">
      <Helmet>
          <title>SWOT Analysis Tool - Optimize Your Business Strategy</title>
          <meta name="description" content="Use our SWOT analysis tool to assess your strengths, weaknesses, opportunities, and threats and optimize your business strategy." />
          <meta name="keywords" content="SWOT, analysis, business, strengths, weaknesses, opportunities, threats, strategy" />
          <meta property="og:title" content="SWOT Analysis Tool" />
          <meta property="og:description" content="Optimize your business strategy with our interactive SWOT analysis tool." />
          <meta property="og:url" content="https://example.com" />
        </Helmet>
      <Header />
      <CookieBanner onAccept={handleConsent} onDecline={handleConsent} />

      {/* Conditionally load Microsoft Clarity based on user consent */}
      {clarityEnabled && <MicrosoftClarity clarityId="o30sm6xmo0" />}

      <div className="swot-section">
        <div className="swot-row">
          <div className="swot-column">
            <h2 className="header-style">Strengths</h2>
            <Table
                data={strengths}
                onAddRow={() => handleAddRow(setStrengths, strengths, "Strength")}
                onUpdateRow={(index, updatedRow) => handleUpdateRow(index, updatedRow, strengths, setStrengths)}
                onRemoveRow={(index) => handleRemoveRow(index, strengths, setStrengths)}
            />
          </div>
          <div className="swot-column">
            <h2 className="header-style">Weaknesses</h2>
            <Table
                data={weaknesses}
                onAddRow={() => handleAddRow(setWeaknesses, weaknesses, "Weakness")}
                onUpdateRow={(index, updatedRow) => handleUpdateRow(index, updatedRow, weaknesses, setWeaknesses)}
                onRemoveRow={(index) => handleRemoveRow(index, weaknesses, setWeaknesses)}
            />
          </div>
        </div>
        <div className="swot-row">
          <div className="swot-column">
            <h2 className="header-style">Opportunities</h2>
            <Table
                data={opportunities}
                onAddRow={() => handleAddRow(setOpportunities, opportunities, "Opportunity")}
                onUpdateRow={(index, updatedRow) => handleUpdateRow(index, updatedRow, opportunities, setOpportunities)}
                onRemoveRow={(index) => handleRemoveRow(index, opportunities, setOpportunities)}
            />
          </div>
          <div className="swot-column">
            <h2 className="header-style">Threats</h2>
            <Table
                data={threats}
                onAddRow={() => handleAddRow(setThreats, threats, "Threat")}
                onUpdateRow={(index, updatedRow) => handleUpdateRow(index, updatedRow, threats, setThreats)}
                onRemoveRow={(index) => handleRemoveRow(index, threats, setThreats)}
            />
          </div>
        </div>
        <div className="export-btn-container">
          <button onClick={handleExportCSV} className="export-btn">Export tables as CSV</button>
        </div>
      </div>


      <div className="switch-container">
        <h1 className="switch-header">Select Analysis Inputs</h1>
        <ToggleSwitch
          labels={{
            left: {
              title: 'Total',
              value: 'left',
              tooltip: 'This option calculates the analysis based on the total score.',
            },
            right: {
              title: 'Average',
              value: 'right',
              tooltip: 'This option calculates the analysis based on the average score.',
            },
          }}
          onChange={handleSwitchChange}
        />
      </div>

      <StrategyText
        sValue={sValue}
        wValue={wValue}
        oValue={oValue}
        tValue={tValue}
      />

      <div className="swot-chart-section">
        <h1>SWOT Analysis Graph</h1>
        <div className="swot-chart">
          <SwotChart
            sValue={sValue}
            wValue={-wValue}
            oValue={oValue}
            tValue={-tValue}
          />
        </div>
      </div>
      <Footer />
    </div>
      </HelmetProvider>
  );
};

export default App;
