import { useEffect } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';

const MicrosoftClarity = ({ clarityId }) => {
  useEffect(() => {
    console.log('MicrosoftClarity component initialized'); // Log when the component is initialized

    // Function to load Microsoft Clarity script asynchronously
    const loadMicrosoftClarity = () => {
       // Log when attempting to load the script
      return new Promise((resolve, reject) => {
        if (!window.clarity) {
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", clarityId);


          // Resolve once the script is loaded
          const script = document.querySelector(`script[src="https://www.clarity.ms/tag/${clarityId}"]`);
          if (script) {
            script.onload = () => {
               // Log when the script loads
              resolve();
            };
            script.onerror = (error) => {
               // Log if an error occurs
              reject(error);
            };
          } else {
             // Log if script tag is missing
            reject('Script not found');
          }
        } else {

          resolve(); // If Clarity is already loaded, resolve immediately
        }
      });
    };

    // Check if the user has given consent for analytics cookies
    const consent = getCookieConsentValue('userConsent');
     // Log the consent value

    if (consent === 'true') {
      // Log if consent is true

      loadMicrosoftClarity()
        .then(() => {
          // Log successful load
        })
        .catch((error) => {
          // Log if loading fails
        });
    } else {
       // Log if consent is not given
    }
  }, [clarityId]);

  return null; // This component doesn't render anything to the UI
};

export default MicrosoftClarity;
