import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="app-header">
      <h1>SWOT Analysis Tool</h1>
      <nav>
        <ul>
          <li><a href="#strengths">Strengths</a></li>
          <li><a href="#weaknesses">Weaknesses</a></li>
          <li><a href="#opportunities">Opportunities</a></li>
          <li><a href="#threats">Threats</a></li>
        </ul>
        {/* Buy Me a Coffee button */}
        {/*<a
            href="https://www.buymeacoffee.com/nieco"
            target="_blank"
            rel="noopener noreferrer"
            className="buy-coffee"
        >
          <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
              className="coffee-button"
          />
        </a>*/}
      </nav>
    </header>
  );
};

export default Header;
