// src/components/CookieBanner.js

import React, { useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

const CookieBanner = ({ onAccept, onDecline }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="userConsent"
      style={{ background: "#00c2ff" }}
      buttonStyle={{ color: "#4e503b", fontSize: "16px", marginRight: '100px' }}
      declineButtonStyle={{ color: "#fff", background: "#9a9a9a", fontSize: "13px", marginRight: '2px' }}
      expires={150}
      enableDeclineButton
      onAccept={() => {
        console.log('User accepted cookies');
        Cookies.set('userConsent', 'true');
        onAccept(true); // Pass consent status to parent
      }}
      onDecline={() => {
        console.log('User declined cookies');
        Cookies.set('userConsent', 'false');
        onDecline(false); // Pass consent status to parent
      }}
    >
      This website uses cookies to enhance the user experience.{" "}
      <span style={{ fontSize: "13px" }}>
        You can accept or decline cookies according to your preferences.
      </span>

      <button
        onClick={toggleDetails}
        style={{
          background: "none",
          border: "none",
          color: "#4e503b",
          cursor: "pointer",
          textDecoration: "underline",
          fontSize: "13px",
          marginLeft: "10px"
        }}
      >
        {showDetails ? "Hide details" : "Show details"}
      </button>

      {showDetails && (
        <div style={{ marginTop: "10px", fontSize: "13px", color: "#fff" }}>
          <p><strong>Necessary Cookies:</strong> These cookies are essential for the website to function properly. They cannot be disabled.</p>
          <p><strong>Analytics Cookies:</strong> These cookies help us understand how our website is being used. We use this information to improve our website and user experience.</p>
        </div>
      )}
    </CookieConsent>
  );
};

export default CookieBanner;
