import React from 'react';

const StrategyText = ({ sValue, wValue, oValue, tValue }) => {
  // Calculate the total scores for each axis
  const totalSW = sValue - wValue;
  const totalOT = oValue - tValue;

  // Determine the strategy text based on the quadrant
  let strategyName = '';
  let strategyNote = '';

  if (totalSW > 0 && totalOT > 0) {
    strategyName = 'Offensive Strategy';
    strategyNote = 'An offensive strategy focuses on maximizing strengths and taking advantage of opportunities to grow and gain a competitive edge.';
  } else if (totalSW < 0 && totalOT > 0) {
    strategyName = 'Alliance Strategy';
    strategyNote = 'An alliance strategy involves partnering with others to complement weaknesses and leverage opportunities.';
  } else if (totalSW < 0 && totalOT < 0) {
    strategyName = 'Diversification Strategy';
    strategyNote = 'A defensive strategy aims to protect the company by addressing weaknesses and mitigating threats.';
  } else if (totalSW > 0 && totalOT < 0) {
    strategyName = 'Defensive Strategy';
    strategyNote = 'A diversification strategy seeks to expand or shift the company’s focus to reduce reliance on current strengths and to navigate threats.';
  } else {
    strategyName = 'No Clear Strategy Identified';
    strategyNote = 'The current situation does not suggest a clear strategic direction. Further analysis may be needed.';
  }

  return (
    <div style={strategyContainerStyle}>
      <h2 style={strategyHeaderStyle}>Recommended Strategy</h2>
      <div style={strategyTextContainerStyle}>
        <h3 style={strategyNameStyle}>{strategyName}</h3>
        <p style={strategyNoteStyle}>{strategyNote}</p>
      </div>
    </div>
  );
};

// Styles
const strategyContainerStyle = {
  backgroundColor: '#007ACC',
  borderRadius: '10px',
  padding: '20px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  margin: '20px 0',
  maxWidth: '1200px',
  width: '100%',
};

const strategyHeaderStyle = {
  color: 'white',
  marginBottom: '20px',
  fontSize: '2em',  // Match the size of the SWOT Analysis Graph header
};

const strategyTextContainerStyle = {
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '20px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  width: 'calc(100% - 40px)', /* Ensure the white area is 20px from each side */
  margin: '0 auto', /* Center the white box within the blue area */
  maxWidth: '1160px', /* To align with the graph area */
};

const strategyNameStyle = {
  margin: 0,
  fontSize: '1.6em',
  fontWeight: 'bold',
  marginBottom: '10px', /* Space between the strategy name and the note */
};

const strategyNoteStyle = {
  margin: 0,
  fontSize: '1.2em',
};

export default StrategyText;
